import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function OutlinedCard(props) {
    const classes = useStyles();

    const [summaryIntro, updateSummaryIntro] = React.useState([]);
    const [summaryBullets, updateSummaryBullets] = React.useState([]);

    React.useEffect(() => {
        updateSummaryIntro(props.summary)
        updateSummaryBullets(props.bullets)
    }, [props.summary, props.bullets])

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    Resumé
                </Typography>
                <Typography variant="body2" align="left" component="p">
                    {summaryIntro}
                    <ul>{summaryBullets.map(bullet => <li> {bullet} </li>)}</ul>
                </Typography>
            </CardContent>
        </Card>
    );
}